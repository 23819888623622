import React from 'react';
import { useLocation } from '@reach/router';
import { LayoutKometa } from '../../../layouts/LayoutKometa';
import { ProductKometa } from '../../../products/kometa';
import { ProductBreadcrumbs } from '../../../components/ProductBreadcrumbs';
import { ProductSectionTitle } from '../../../components/ProductSectionTitle';
import { ProductSectionParagraph } from '../../../components/ProductSectionParagraph';
import { FaqItem } from '../../../components/FaqItem';

const KometaFaqPage = () => {
  const location = useLocation();

  return (
    <LayoutKometa>
      <ProductBreadcrumbs
        productTitle={ProductKometa.title}
        productUrl={ProductKometa.url}
        breadcrumbs={[{ url: location.pathname, title: 'FAQ & License' }]}
      />
      <section className="mb-10">
        <ProductSectionTitle>Frequently asked questions</ProductSectionTitle>
        <div className="grid grid-cols-1 gap-16 row-gap-8 lg:grid-cols-2">
          <div className="space-y-8">
            <FaqItem title={`How much does ${ProductKometa.title} cost?`}>
              {ProductKometa.title} is completely free. You don't have to
              purchase a license for yourself, or for your team. No attribution
              required.
            </FaqItem>
            <FaqItem
              title={`On which projects ${ProductKometa.title} can be used?`}
            >
              {ProductKometa.title} can be used on unlimited personal, client
              and commercial projects.
            </FaqItem>
            <FaqItem title={`Are any JS frameworks supported?`}>
              {ProductKometa.title} is available in pure HTML, VueJS and React.
            </FaqItem>
          </div>
          <div className="space-y-8">
            <FaqItem title="What version of Tailwind CSS is required?">
              {ProductKometa.title} is built on top of Tailwind CSS 1.4.1. Note
              that this version of Tailwind CSS doesn't support IE 11.
            </FaqItem>
            <FaqItem title="Can I create derivative products?">
              You are not allowed to create UI kits, templates, themes, builders
              and anything else that is a derivative product of{' '}
              {ProductKometa.title}.
            </FaqItem>
          </div>
        </div>
      </section>
      <section>
        <ProductSectionTitle>License</ProductSectionTitle>
        <ProductSectionParagraph>
          Permission is hereby granted, free of charge, to any person obtaining
          a copy of this software and associated documentation files (the
          "Software"), to deal in the Software without restriction, including
          without limitation the rights to use, copy, modify, merge, publish,
          distribute, except sublicensing and/or selling copies of the Software,  and to
          permit persons to whom the Software is furnished to do so, subject to
          the following conditions:
          <br />
          <br />
          THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND,
          EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF
          MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT.
          IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY
          CLAIM, DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT,
          TORT OR OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE
          SOFTWARE OR THE USE OR OTHER DEALINGS IN THE SOFTWARE.
        </ProductSectionParagraph>
      </section>
    </LayoutKometa>
  );
};

export default KometaFaqPage;
